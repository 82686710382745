<template>
  <v-theme-provider dark>
    <base-section
      id="info-alt"
      class="grey darken-4"
    >
      <v-responsive
        class="mx-auto"
        max-width="1400"
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <base-info />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <base-info-card :title="translations.contact" />

              <base-business-contact
                :business="business"
                dense
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <footer-links />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <footer-links-second />
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_ALL } from '@/App.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'SectionFooter',

    components: {
      FooterLinks: () => import('@/components/FooterLinks'),
      FooterLinksSecond: () => import('@/components/FooterLinksSecond'),
    },
    methods: {
      documentToHtmlString,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
      business () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: 'Address',
            text: this.translations?.footerLocale,
          },
          {
            icon: 'mdi-cellphone',
            title: 'Phone',
            text: this.documentToHtmlString(this.translations?.footerPhone),
          },
          {
            icon: 'mdi-email',
            title: 'Email',
            text: this.translations?.footerEmail,
          },
        ]
      },
    },
  }
</script>

<style lang="sass">
  #info-alt a
    text-decoration: none
</style>
